import React from "react";

import blurCircle from "./../../../assets/images/pngs/blurCircle.png";
import airdropBg from "./../../../assets/images/pngs/airdropBg.png";
import airDropImg from "./../../../assets/images/pngs/airDropImg.png";
import comingBtnImg from "./../../../assets/images/pngs/comingBtnImg.png";

export default function AirDrop() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${airdropBg})` }}
        className="bg-cover bg-no-repeat mt-10"
      >
        <div className="max-w-[1380px] mx-auto relative px-4 md:px-6 lg:px-8 xl:px-12">
          <div
            data-aos="fade-up"
            className="flex flex-col min-[875px]:flex-row justify-center items-center gap-4 min-[875px]:gap-8 lg:gap-12 xl:gap-16"
          >
            <div
              data-aos="fade-up"
              className="absolute w-full flex justify-center top-[-200px] z-10"
            >
              <img
                src={blurCircle}
                alt="blurCircle img"
                className="md:max-w-[349px]"
              />
            </div>
            <div
              data-aos="fade-right"
              className="max-w-80 min-[875px]:max-w-96 xl:max-w-none md:p-8 lg:p-12 xl:p-16 relative z-30"
            >
              <a href="https://shuffle.com/token?r=ExtraBonus">
                <img
                  src={airDropImg}
                  alt="airdrop img"
                  className="w-full md:-mt-16 lg:-mt-20 xl:-mt-28"
                />
              </a>
            </div>
            <div
              data-aos="fade-up"
              className="min-[875px]:max-w-[455px] xl:max-w-[545px] text-center min-[875px]:text-start pb-6 sm:pb-8 md:pb-10 min-[875px]:pb-0 relative z-30"
            >
              <h2
                data-aos="fade-up"
                className="rubik text-secondary text-[22px] lg:text-3xl xl:text-[38px] font-bold [text-shadow:1px_4px_0_#000;]"
              >
                SHFL AIRDROP 2
              </h2>
              <p
                data-aos="fade-up"
                className="rubik text-secondary !leading-[150%] text-sm md:text-base xl:text-lg !opacity-70 mt-3 md:mt-4 mb-4 md:mb-6 lg:mb-8 xl:mb-10"
              >
                Play and earn $SHFL tokens for the next airdrop! Every bet
                counts towards the airdrop and earns you more and more free
                tokens as you progress through the VIP system.
              </p>
              <a
                href="https://shuffle.com/token?r=ExtraBonus"
                data-aos="fade-up"
                style={{ backgroundImage: `url(${comingBtnImg})` }}
                className="[background-size:100%_100%] bg-no-repeat rubik text-secondary md:text-lg lg:text-xl xl:text-2xl font-bold transition-all ease-in-out duration-300 hover:scale-95 hover:bg-primary px-4 md:px-6 lg:px-8 xl:px-10 py-3 md:py-4 lg:py-5 xl:py-6"
              >
                Coming 14th December 2024
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
