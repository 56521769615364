import React from 'react'
import howToRegisterBg from './../../../assets/images/pngs/howToRegisterBg.png'
import howToRegisterOverlay from './../../../assets/images/pngs/howToregisterOverlay.png'
import howToregister1 from './../../../assets/images/pngs/howToRegister1.png'
import howToregister2 from './../../../assets/images/pngs/howToRegister2.png'
import howToregister3 from './../../../assets/images/pngs/howToRegister3.png'
import howToregister4 from './../../../assets/images/pngs/howToRegister4.png'

export default function HowToRegister() {
    return (
        <>
            <div style={{ backgroundImage: `url(${howToRegisterBg})` }} className="[background-size:100%_100%] bg-no-repeat mb-8 sm:mb-10 md:mb-18 lg:mb-32 xl:mb-40">
                <div className="inter max-w-[1380px] mx-auto relative px-4 md:px-6 lg:px-8 xl:px-12 py-8 md:py-12 lg:py-20">

                    <div className='flex flex-col xl:flex-row justify-between items-center gap-6 xl:gap-10'>

                        <div data-aos="fade-up" style={{ backgroundImage: `url(${howToRegisterOverlay})` }} className='[background-size:100%] lg:[background-size:100%_100%] bg-no-repeat text-center flex flex-col justify-center items-center w-full sm:max-w-[50%] xl:max-w-[459px] lg:-ml-12 xl:ml-[-130px] py-20 md:py-28 lg:py-32'>
                            <h3 className='text-secondary font-bold text-4xl md:text-5xl mb-4 md:mb-5 lg:mb-6 xl:mb-7'>How To</h3>
                            <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth"
                                className="rubik block text-secondary bg-primary rounded-md uppercase leading-[150%] text-xl lg:text-2xl font-bold transition-all ease-in-out duration-300 hover:bg-primary/80 hover:scale-95 hover:shadow-[0_0_5px] hover:shadow-primary/80 px-8 lg:px-10 xl:px-12 py-3 lg:py-4 xl:py-5"
                            >
                                REGISTER
                            </a>
                        </div>

                        <div className="flex flex-wrap lg:flex-nowrap justify-center gap-6 lg:gap-4">
                            <div data-aos="fade-up" className='relative overflow-hidden md:max-w-96 lg:max-w-[250px] xl:max-w-[193px]'>
                                <img src={howToregister1} alt="how to register card img" />
                                <div className="absolute bottom-0 left-0 w-full bg-primary text-center shadow-primary shadow-[0_-40px_30px_25px]  backdrop-blur rounded-xl py-6 lg:py-3">
                                    <p className='text-secondary px-2'>Visit <span className='text-lg md:text-lg lg:text-lg font-bold'>Shuffle.com</span></p>
                                </div>
                            </div>
                            <div data-aos="fade-up" className='relative overflow-hidden md:max-w-96 lg:max-w-[250px] xl:max-w-[193px]'>
                                <img src={howToregister2} alt="how to register card img" />
                                <div className="absolute bottom-0 left-0 w-full bg-primary text-center shadow-primary shadow-[0_-40px_30px_25px] backdrop-blur rounded-xl py-6 lg:py-3">
                                    <p className='text-secondary px-2'>Click on <span className='text-lg md:text-lg lg:text-lg font-bold'>Register</span></p>
                                </div>
                            </div>
                            <div data-aos="fade-up" className='relative overflow-hidden md:max-w-96 lg:max-w-[250px] xl:max-w-[193px]'>
                                <img src={howToregister3} alt="how to register card img" />
                                <div className="absolute bottom-0 left-0 w-full bg-primary text-center shadow-primary shadow-[0_-40px_30px_25px] backdrop-blur rounded-xl py-6 lg:p-3 xl:pt-0">
                                    <p className='text-secondary px-2 lg:px-0 text-lg md:text-sm'>Fill in your details & enter code <span className='font-black'>"ExtraBonus"</span></p>
                                </div>
                            </div>
                            <div data-aos="fade-up" className='relative overflow-hidden md:max-w-96 lg:max-w-[250px] xl:max-w-[193px]'>
                                <img src={howToregister4} alt="how to register card img" />
                                <div className="absolute bottom-0 left-0 w-full bg-primary text-center shadow-primary shadow-[0_-40px_30px_25px] backdrop-blur rounded-xl py-6 lg:py-3 xl:pt-0">
                                    <p className='text-secondary px-4 xl:px-2'>Enjoy the Best <span className='text-lg md:text-lg lg:text-lg font-bold text-nowrap'>VIP Rewards!</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
