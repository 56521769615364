import React, { useEffect } from "react";

import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import Home from "./components/pages/Home";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);
  return (
    <>
      <div className="bg-almostBlack overflow-hidden">
        <Header />
        <Home />
        <Footer />
      </div>
    </>
  );
}

export default App;
