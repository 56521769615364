import React from "react";

import bonusesBg from "./../../../assets/images/pngs/bonusesBg.png";
import boostCardImg1 from "./../../../assets/images/pngs/boostCardImg1.png";
import boostCardImg2 from "./../../../assets/images/pngs/boostCardImg2.png";
import boostCardImg3 from "./../../../assets/images/pngs/boostCardImg3.png";
import vipClubBg from "./../../../assets/images/pngs/vipClubBg.png";
import vipClubImg from "./../../../assets/images/pngs/vipClubImg.png";
import { vipCardData } from "../../helper/HomeComponentsData";


import singUpBonuscardImg1 from './../../../assets/images/pngs/singUpBonuscardImg1.png'
import singUpBonuscardImg2 from './../../../assets/images/pngs/singUpBonuscardImg2.png'

export default function Bonuses() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${bonusesBg})` }}
        className="[box-shadow:inset_0_15px_20px_12px_#0e0814] sm:[background-size:100%_100%] bg-no-repeat relative z-10 "
      >
        <div className="max-w-[1380px] mx-auto px-4 md:px-6 lg:px-8 xl:px-12 pb-8 sm:pb-10 md:pb-18 lg:pb-32 xl:pb-40">
          <div
            data-aos="fade-up"
            className="flex flex-wrap md:flex-nowrap justify-center md:justify-between gap-7 pb-8 md:pb-12 lg:pb-18 xl:pb-24"
          >
            <div
              data-aos="fade-up"
              className="max-w-full min-[475px]:max-w-[70%] sm:max-w-[47%] md:max-w-96"
            >
              <img
                src={boostCardImg1}
                alt="Boost Card Img"
                className="lg:-mt-4 xl:-mt-8"
              />
            </div>
            <div
              data-aos="fade-up"
              className="max-w-full min-[475px]:max-w-[70%] sm:max-w-[47%] md:max-w-96"
            >
              <img
                src={boostCardImg2}
                alt="Boost Card Img"
                className="lg:-mt-4 xl:-mt-8"
              />
            </div>
            <div
              data-aos="fade-up"
              className="max-w-full min-[475px]:max-w-[70%] sm:max-w-[47%] md:max-w-96"
            >
              <img
                src={boostCardImg3}
                alt="Boost Card Img"
                className="lg:-mt-4 xl:-mt-8"
              />
            </div>
          </div>
          <div className="flex flex-wrap min-[875px]:flex-nowrap justify-center gap-5 sm:gap-6 lg:gap-7 xl:gap-8 pb-8 md:pb-14 lg:pb-20 xl:pb-24">
            <div data-aos="fade-right" className="relative w-full max-w-[580px]">
              <img src={singUpBonuscardImg1} alt="Faq Card Img" className="h-full w-full" />
              <div className="absolute w-full text-center bottom-[7%] min-[375px]:bottom-[7.8%] min-[475px]:bottom-[9%] min-[875px]:bottom-[8.8%] lg:bottom-[9%] xl:bottom-12 left-0">
                <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth" className="inter text-lg sm:text-2xl md:text-3xl min-[875px]:text-xl lg:text-2xl xl:text-3xl font-medium bg-[#FFDE01] transition-all ease-in-out duration-300 hover:bg-[#FFF200] hover:[text-shadow:2px_2px_2px_#FFDE01] rounded-md md:rounded-xl py-2.5 min-[375px]:py-3 min-[425px]:py-5 min-[475px]:py-6 sm:py-5 lg:py-[25px] xl:py-6 px-8 min-[375px]:px-14 min-[475px]:px-24 sm:px-28 md:px-32 min-[875px]:px-20 lg:px-[112px] xl:px-28">
                  Claim Bonus
                </a>
              </div>
            </div>
            <div data-aos="fade-left" className="relative w-full max-w-[580px]">
              <img src={singUpBonuscardImg2} alt="Faq Card Img" className="h-full w-full" />
              <div className="absolute w-full text-center bottom-[15.8%] min-[375px]:bottom-[16%] min-[500px]:bottom-[17%] min-[875px]:bottom-[16.5%] lg:bottom-[16.6%] xl:bottom-[16.3%] left-0">
                <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth" className="inter text-lg sm:text-2xl md:text-3xl min-[875px]:text-xl lg:text-2xl xl:text-3xl font-medium bg-[#FFDE01] transition-all ease-in-out duration-300 hover:bg-[#FFF200] hover:[text-shadow:2px_2px_2px_#FFDE01] rounded-md md:rounded-xl py-3.5 min-[375px]:py-4 min-[425px]:py-6 min-[520px]:py-8 min-[875px]:py-5 lg:py-[25px] xl:py-[21px] px-12 min-[375px]:px-16 min-[425px]:px-24 min-[475px]:px-32 sm:px-40 min-[875px]:px-20 lg:px-[112px] xl:px-28">
                  Claim Bonus
                </a>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            style={{ backgroundImage: `url(${vipClubBg})` }}
            className="[background-size:100%_100%] bg-no-repeat rounded-3xl min-[870px]:rounded-none"
          >
            <div className="flex flex-col min-[870px]:flex-row items-end mt-4 md:mt-8 lg:mt-12 xl:mt-14 lg:gap-14 xl:gap-[72px]">
              <div className="min-[870px]:w-2/3 lg:w-1/2 place-self-center px-4 py-8 md:py-8 lg:p-8">
                <h2
                  data-aos="fade-up"
                  className="rubik text-secondary font-black text-[22px] md:text-3xl lg:text-4xl xl:text-[42px] [text-shadow:1px_2px_0_rgba(0,0,0,0.25)]"
                >
                  VIP CLUB & BONUSES
                </h2>
                <p
                  data-aos="fade-up"
                  className="inter text-secondary text-base xl:text-lg leading-[150%] !opacity-80 mt-1.5 md:mt-2 xl:mt-3 mb-4 md:mb-5 xl:mb-7"
                >
                  You can easily become part of the SHUFFLE VIP elite and
                  benefit from immense advantages such as Rakeback, Monthly
                  Bonuses, Daily Reloads and many other bonuses.
                </p>
                <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth"
                  data-aos="fade-up"
                  className="inter bg-secondary md:text-lg font-bold rounded-md transition-all ease-in-out duration-300 hover:text-primary hover:bg-secondary/95 hover:scale-95 hover:shadow-md px-5 md:px-6 lg:px-7 xl:px-8 py-2 md:py-3 xl:py-4"
                >
                  See all VIP Bonuses
                </a>
              </div>
              <div data-aos="fade-up" className="md:w-1/2">
                <img
                  src={vipClubImg}
                  alt="vip Club Main img"
                  className="-mt-6 sm:-mt-10 md:-mt-12 xl:-mt-14"
                />
              </div>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="flex flex-wrap justify-center gap-4 md:gap-6 lg:gap-8 xl:gap-11  md:gap-y-5 lg:gap-y-6 xl:gap-y-7 pt-4 md:pt-6 lg:pt-8 xl:pt-10"
          >
            {vipCardData.map((item, index) => {
              return (
                <div
                  data-aos="fade-up"
                  key={index}
                  style={{ backgroundImage: `url(${item.vipCardBg})` }}
                  className="bg-cover bg-no-repeat flex flex-col-reverse lg:flex-row items-center justify-center lg:justify-between gap-1 lg:gap-3 w-full min-[500px]:w-[48%] relative rounded-xl"
                >
                  <div
                    data-aos="fade-right"
                    className="text-center lg:text-start p-4 pt-0 md:p-6 lg:p-8`"
                  >
                    <h3 className="rubik text-secondary text-xl md:text-2xl lg:text-[28px] xl:text-[38px] font-black leading-[120%] [text-shadow:1px_2px_0_rgba(0,0,0,0.25);]">
                      {item.heading}
                    </h3>
                    <p
                      className={`inter text-sm sm:text-base lg:text-lg opacity-80 !leading-[150%] text-secondary mt-1.5 lg:mt-2.5 ${index === 0 ? "lg:pr-14" : ""
                        }`}
                    >
                      {item.text}
                    </p>
                  </div>
                  <div
                    data-aos="fade-left"
                    className="max-w-32 md:max-w-36 lg:max-w-48 xl:max-w-56 max-h-38 p-2 md:p-3 lg:p-4 xl:p-5"
                  >
                    <img src={item.cardMainImg} alt="Card Img" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
