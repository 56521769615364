import React, { useState } from "react";

import { LessSvg, PlusSvg } from "../../helper/Icons";
import { faqData } from "../../helper/HomeComponentsData";
import blurCircle from "./../../../assets/images/pngs/blurCircle.png";
import pokerFlush from "./../../../assets/images/pngs/pokerFlush.png";

export default function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="relative max-w-[1380px] mx-auto ">
      <div
        data-aos="fade-up"
        className="max-w-[1380px] mx-auto overflow-hidden px-4 md:px-6 lg:px-8 xl:px-12 relative z-30"
      >
        <h1
          data-aos="fade-up"
          className="rubik text-center text-secondary text-[22px] md:text-2xl lg:text-3xl xl:text-[38px] font-extrabold pt-10 md:pt-16 lg:pt-28 xl:pt-36 pb-7 md:pb-12 lg:pb-16 xl:pb-20 relative z-30"
        >
          Frequently Asked Questions (FAQ)
        </h1>
        <div
          data-aos="fade-up"
          className="flex flex-wrap justify-center gap-x-4 md:gap-x-5 lg:gap-x-6 xl:gap-x-7 gap-y-3 md:gap-y-4 pb-10 md:pb-16 lg:pb-28 xl:pb-36 relative z-30"
        >
          {faqData.map((item, index) => (
            <div key={index} className="w-full lg:max-w-[465px] xl:max-w-[575px]">
              <div
                data-aos="fade-right"
                onClick={() => toggleAnswer(index)}
                role="button"
                aria-expanded={openIndex === index}
                className="flex justify-between items-center rounded-lg min-[875px]:rounded-xl border border-grey bg-esbony transition-all ease-in-out duration-300 hover:border-almostBlack px-4 min-[400px]:px-6 py-2.5 min-[400px]:py-5 gap-4 cursor-pointer"
              >
                <h2
                  data-aos="fade-left"
                  className="rubik text-secondary font-bold leading-[150%] text-sm md:text-base"
                >
                  {item.question}
                </h2>
                <span>{openIndex === index ? <LessSvg /> : <PlusSvg />}</span>
              </div>
              {openIndex === index && (
                <div
                  data-aos="fade-up"
                  className="bg-esbony rounded-xl border border-grey text-sm md:text-base px-2.5 min-[400px]:px-5 py-3 min-[400px]:py-4 space-y-0.5 lg:space-y-1 xl:space-y-2"
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              )}
            </div>
          ))}
        </div>

      </div>
      <div
        data-aos="fade-up"
        className="absolute flex justify-center top-0 -left-28 z-10"
      >
        <img src={blurCircle} alt="blurCircle img" className="max-w-[649px]" />
      </div>
      <div
        data-aos="fade-up"
        className="absolute flex justify-center bottom-0 -right-28 z-10"
      >
        <img src={blurCircle} alt="blurCircle img" className="max-w-[649px]" />
      </div>
      <div
        data-aos="fade-up"
        className="absolute flex justify-center bottom-[10%] right-[20%] z-10"
      >
        <img src={pokerFlush} alt="pokerFlush img" className="max-w-[649px]" />
      </div>
    </div>
  );
}
