import rankBackBg from './../../assets/images/pngs/rankBackBg.png'
import rankBackImg from './../../assets/images/pngs/rankbackImg.png'
import rankUpBg from './../../assets/images/pngs/rankUpBG.png'
import rankUpImg from './../../assets/images/pngs/rankUpImg.png'
import weeklyBonusBg from './../../assets/images/pngs/weeklyBonusBg.png'
import weeklyBonusImg from './../../assets/images/pngs/weeklyBonusImg.png'
import monthlyBonusBg from './../../assets/images/pngs/monthlyBonusBg.png'
import monthlyBonusImg from './../../assets/images/pngs/monthlyBonusImg.png'

export const vipCardData = [
    { vipCardBg: rankBackBg, heading: 'Rakeback', text: 'Earn Rakeback with every Casino bet placed', cardMainImg: rankBackImg },
    { vipCardBg: rankUpBg, heading: 'Rank-Up Bonus', text: 'Rank-UP Bonus & Personalized VIP Transfer Bonus', cardMainImg: rankUpImg },
    { vipCardBg: weeklyBonusBg, heading: 'Weekly Bonus', text: 'Earn Bigger Rewards with each completed VIP-Level', cardMainImg: weeklyBonusImg },
    { vipCardBg: monthlyBonusBg, heading: 'Monthly Bonus', text: 'Earn a monthly bonus and top player bonus as well, double the fun.', cardMainImg: monthlyBonusImg },
]
// export const howToRegisterData = [
//     { img: howToregister1, text: '', boldtext: '' },
//     { img: howToregister2, text: '', boldtext: '' },
//     { img: howToregister3, text: '', boldtext: '' },
//     { img: howToregister4, text: '', boldtext: '' },
// ]
export const faqData = [
    {
        question: 'Shuffle - New Crypto Casino & Sports Betting Platform 2024',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100
                '>Shuffle.com</a> is a new and innovative online casino & sportsbook that has the best range of casino games and sporting events and has been constantly growing and optimising its range of offers and sports since its launch in February 2023. The platform's first major milestone occurred in March 2024, when Shuffle became the world's first online casino to successfully launch a token via LBP and raise over $20 million. The $SHFL token can be used as a wagering asset and is regularly burned and bought back by Shuffle. Holders of the token benefit from early access to new features and ExtraBonus bonuses. At the launch of the token, Shuffle released the first of 3 airdrops rewarding all active players with $SHFL tokens.
            </p>
        `
    },
    {
        question: 'Variety of Online Casino Games',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100
                '>Shuffle.com</a> is a new and innovative online casino & sportsbook that has the best range of casino games and sporting events and has been constantly growing and optimising its range of offers and sports since its launch in February 2023.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                The platform's first major milestone occurred in March 2024, when Shuffle became the world's first online casino to successfully launch a token via LBP and raise over $20 million. The $SHFL token can be used as a wagering asset and is regularly burned and bought back by Shuffle. Holders of the token benefit from early access to new features and ExtraBonus bonuses.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                At the launch of the token, Shuffle released the first of 3 airdrops rewarding all active players with $SHFL tokens.
            </p>
        `
    },
    {
        question: 'Slots',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Start with slots and immerse yourself in the fantastic world of gambling, choose your favourites from over <span class='opacity-100 font-medium'>3,000+ slots</span> available at Shuffle.
            </p>
        `
    },
    {
        question: 'Live Casino & Dealer Games',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Experience the ultimate live casino experience at Shuffle, with real dealers and in real time, while playing entertaining game shows or winning at blackjack and other live dealer games.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100
                '>Shuffle.com</a> 
                 offers a wide selection of premium live games such as <span class='font-medium'>Live Roulette, Live Baccarat, Live Blackjack, Live Craps</span> and game shows such as <span class='font-medium'>LMonopoly Live, Crazy Time, Lightning Roulette</span> and more for real winnings
            </p>
        `
    },
    {
        question: 'Shuffle House Casino Games',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                If you like quick and easy games, then there's no way round the Shuffle Originals. These house games from Shuffle are very popular with players as they have some of the highest RTPs in the industry, giving you the best chance of winning big, alongside a minimalist design and easy to understand mechanics.
            </p>

            <p class='rubik leading-[150%] text-secondary opacity-70'>
                <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>Shuffle.com</a> 
                The Shuffle Originals currently consist of the top titles
                 <a href='https://shuffle.com/how-to-play-dice-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Dice</a>,
                 <a href='https://shuffle.com/how-to-play-mines-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Mines</a>,
                 <a href='https://shuffle.com/how-to-play-plinko-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Plinko</a>,
                 <a href='https://shuffle.com/how-to-play-hilo-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Hilo</a>,
                 <a href='https://shuffle.com/how-to-play-wheel-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Wheel</a>,
                 <a href='https://shuffle.com/how-to-play-roulette-on-shuffle/' class='underline transition-all ease-in-out hover:opacity-100'>Roulette</a>,                  
                 and more, which are constantly being developed and improved.
                Shuffle places great emphasis on transparency and therefore all Originals are completely <a href='https://shuffle.com/how-does-provably-fair-work-guide/' class='underline transition-all ease-in-out hover:opacity-100'>provably fair</a> and have the highest RTP of up to <span class='font-medium'>99.46%</span> - to ensure that players get a fair and transparent gaming experience with Crypt
            </p>
        `
    },
    {
        question: 'All Casino Game Providers',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                At Shuffle you can play casino games from the best providers in the industry including 
                <span class='font-medium'>Hacksaw Gaming, Pragmatic Play, NoLimit City, Push Gaming, Play'n Go, Relax Gaming, BGaming, </span> Evolution and more.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Games you can play on the web on desktop, tablet or mobile include <span class='font-medium'>Gates of Olympus, Sugar Rush, Reactoonz, Wanted Dead or a Wild, Le Bandit, Mental</span> and thousands more with new slots added weekly.
            </p>
        `
    },
    {
        question: 'Betting Promotions & Bonuses',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Shuffle provides an easy-to-use interface and an ExtraBonus experience for all players with regular promotions & bonuses. You can use and benefit from the promotions for casino games or the latest sporting events.
            </p>
                
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                In addition to the weekly Shuffle Races and the <a href='https://shuffle.com/promotions?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>weekly $20k VIP Raffle,</a> you can look forward to great promotions for the best casino games and special sporting events at UFC, NBA, Super Bowl or Cricket and more.
            </p>
        `
    },
    {
        question: 'Shuffle Deposit Bonus',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                At Shuffle Casino, all new players who use the code ” <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>ExtraBonus</a> ” are offered an exquisite welcome bonus. This bonus consists of a 100% deposit bonus on the first deposit in the cryptocurrency of your choice. How to activate this bonus:
            </p>
            <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                <li><span class='font-medium'>Step 1 - </span> Sign up to Shuffle using code ” <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>ExtraBonus</a> “</li>
                <li><span class='font-medium'>Step 2 - </span>Verify your Email & enter basic informations</li>
                <li><span class='font-medium'>Step 3 - </span>Reply to Support Message and Confirm the Terms</li>
                <li><span class='font-medium'>Step 4 - </span>Continue with your Deposit between <span class='font-medium'>$20 - $1,000</span></li>
                <li><span class='font-medium'>Step 5 - </span>Wait for the Bonus to be added and start playing</li>
            </ul>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                In addition to a First Deposit Bonus, Shuffle also offers a wide range of other <a href='https://shuffle.com/promotions?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>promotions</a> and <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>bonuses</a> that you can take advantage of, including numerous promotions and higher cashback on your gameplay with the code ” <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>ExtraBonus</a> ”.
            </p>
        `
    },
    {
        question: 'ExtraBonus Level Ups',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                This limited and special Promotion is only available to players under affiliate/promo code ' ExtraBonus ' and offers more than <span class='font-medium'>$200,000 in extra level up rewards.</span> Every time you reach a new vip level on <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth/' class='underline transition-all ease-in-out hover:opacity-100'>Shuffle.com</a> you will be credited an <span>additional Bonus</span> on top.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Here's how to claim your Enhanced Level Up Rewards:
            </p>
            <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                <li><span class='font-medium'>Step 1 - </span> Sign up to Shuffle using code  ” <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>ExtraBonus</a> “</li>
                <li><span class='font-medium'>Step 2 - </span>Verify your Email & enter basic informations</li>
                <li><span class='font-medium'>Step 3 - </span>Play on Shuffle and reach a new VIP Level</li>
                <li><span class='font-medium'>Step 4 - </span>Message us on <a href='https://x.com/shuffleguide' class='underline transition-all ease-in-out hover:opacity-100'>X (Twitter)</a> or <a href='https://discord.com/invite/DogeGamba' class='underline transition-all ease-in-out hover:opacity-100'>Discord</a> to claim your extra bonus</li>                
            </ul>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Example: you have reached Gold 1 VIP on <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>Shuffle.com</a> and <span class='font-medium'>received a $50 Bonus</span> through the VIP program. For Gold 1 you will receive <span class='font-medium'>another $50 Bonus</span> on top as soon as you contact us and request the reward.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                See all Promotional details here: <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>Increased Level Up Payout Table</a>
            </p>
        `
    },
    {
        question: 'VIP Program & Features',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Shuffle's loyalty program is unlike any other loyalty program out there. Start playing at Shuffle and you will be showered with rewards from the very first second. Every bet you make counts towards your bonuses and simultaneously increases your rakeback, weekly bonus, monthly bonus, earns XP for the VIP level and many more bonuses.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                All VIP bonuses at Shuffle come with no additional wagering requirements or restrictions on wagering & play. There are absolutely no limits on your bonuses, so you can always max out.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Other perks of the VIP program:
            </p>
            <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                <li><span class='font-medium'>Rank-Up Bonus: </span> collect XP and level up to unlock better rewards</li>
                <li><span class='font-medium'>Weekly Bonus: </span> Receive cashback on wager made in the last week</li>
                <li><span class='font-medium'>Monthly Bonus: </span> Earn a big bonus for last month's wagering</li>
                <li><span class='font-medium'>Reload Bonus: </span> Your VIP host sets you a new reload bonus every 7 days</li>
            </ul>
            <p class='rubik leading-[150%] text-secondary opacity-70'>            
                Check out all the <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>other casino & sportsbook rewards</a> from the VIP program at Shuffle and maximise your rewards with <a href='https://shuffle.com/promotions?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>unique player promotions.</a>
            </p>
        `
    },
    {
        question: 'Shuffle in your Language',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Shuffle recognises the importance of serving local markets and has therefore translated the entire website into multiple languages to provide local players with a better and more convenient experience on the platform.
            </p>
             <p class='rubik leading-[150%] text-secondary opacity-70'>
                Take a look at all 11 Languages that Shuffle is available in:
            </p>
            <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                <li><span class='font-medium'>English: </span>  The default language for Shuffle which is available for many English speaking countries, the English version of Shuffle will always be available.</li>

                <li><span class='font-medium'>Français: </span> The French community at Shuffle has been a big part of the site since the beginning, making French one of the first languages added to Shuffle.</li>

                <li><span class='font-medium'>中文: </span> Chinese is the most spoken language globally, and beyond China, many countries such as Singapore, Malaysia, and others consider it a major language. Shuffle is happy to locally accept customers from these countries.</li>

                <li><span class='font-medium'>Español: </span> Players from Spain have seamless access to Shuffle España and a fully translated website with chat function.</li>

                <li><span class='font-medium'>Português:  </span> Portuguese stands out as one of the most widely spoken languages globally, serving as a primary language for significant nations like Brazil, Angola, Cape Verde, and several others.</li>

                <li><span class='font-medium'>한국어: </span> South Korean and Korean-speaking players are always welcome at Shuffle, as of late in a fully translated site for maximum comfort while playing.</li>

                <li><span class='font-medium'>日本語: </span> The Japanese language has opened up a large market that Shuffle is happy to serve and provides entertaining moments for Japanese players.</li>

                <li><span class='font-medium'>Deutsch: </span> The German language is an important part of Shuffle, connecting Germany, Austria and Switzerland and providing instant local access to Shuffle Deutschland.</li>

                <li><span class='font-medium'>Magyar: </span>  Shuffle.com is one of the few providers that wants to create a perfect user experience for local players from Hungary and has added the Hungarian language as a first step.</li>

                <li><span class='font-medium'>Türkçe: </span>  The Turkish community has been a big supporter since the early days of the platform and also has its own chat room for chatting and sharing.</li>

                <li><span class='font-medium'>Pусский: </span>  The Russian language covers most of East Asia and is spoken in many countries there. Russian-speaking countries are therefore most welcome at Shuffle Казино.</li>
            </ul>
        `
    },
    {
        question: 'Safe & Responsible Gambling',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Shuffle is committed to providing all players with adequate player protection and offers a smooth and <span class='font-medium'>instant self-exclusion </span>process where players can exclude themselves from the casino, sportsbook or the entire platform at any time for a set period of time to take a break from gambling.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                In this day and age of fast crypto payments, watching your gambling behaviour and setting limits is more important than ever. Gambling should be seen as <span class='font-medium'>entertainment </span> and <span class='font-medium'>fun,</span> but it can happen that players play stakes that do not fit into their budget. If you have problems with gambling, you can always self-exclude or contact 24/7 live support.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                It is safe to say that Shuffle goes to great lengths to protect players and provides tools to help you set the right limits. Learn more about <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>how to use the Shuffle Vault</a> to play safer.
            </p>
            
        `
    },
    {
        question: 'Cryptocurrencies Available',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                The demand for different payment methods for online gaming is very high, which is why Shuffle accepts a wide range of currencies to cater to players.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                The complete list of cryptocurrencies you can play with at Shuffle:
            </p>
            <div>
                <h4 class='rubik text-secondary font-medium'>Bitcoin (BTC)</h4>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                  When <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>Shuffle.com</a> launched, Bitcoin was the first currency available for players to play with, and today Bitcoin is still used by many players.
                </p>
            </div>
            <div>
                <h4 class='rubik text-secondary font-medium'>Ethereum (ETH)</h4>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                The introduction of the Ethereum blockchain has brought many benefits and made it possible to add more erc-20 currencies to <a href='https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth' class='underline transition-all ease-in-out hover:opacity-100'>Shuffle.com.</a>
                </p>
            </div> 
            <div>
                <h4 class='rubik text-secondary font-medium'>Shuffle (SHFL)</h4>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                    Innovative erc-20 token of the shuffle.com platform which was launched in 2024 with many ExtraBonus perks and buyback & burns.
                </p>
            </div>
            <div>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                    Other Supported Cryptocurrencies
                </p>
                <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                    <li><span class='font-medium'>Litecoin (LTC): </span>  Litecoin is a popular altcoin in the casino scene, at Shuffle you can deposit and withdraw litecoin with low fees.</li>

                    <li><span class='font-medium'>Tron (TRX): </span> The perfect currency for fast, efficient and cost-effective transactions. With TRX you can play in seconds and cash out with the lowest fees on the platform.</li>

                    <li><span class='font-medium'>Ripple (XRP):</span> Ripple is one of the top 10 cryptocurrencies and is characterised by fast and inexpensive transactions via the XRP network at Shuffle</li>

                    <li><span class='font-medium'>Tether (USDT):  </span> Tether USD is the most popular stablecoin currency and provides a stable value in the crypto market since 2014.</li>

                    <li><span class='font-medium'>USD Coin (USDC):   </span> This stablecoin is characterised by its 1:1 peg with the US dollar and is often used as an alternative to USDT.</li>

                    <li><span class='font-medium'>Dogecoin (DOGE): </span> SDogecoin was initially used in forums like Reddit to distribute tips and became the most hyped coin in crypto history in 2021 by <a href='https://x.com/elonmusk>Elon Musk</a>, almost reaching an ATH of $1.</li>

                    <li><span class='font-medium'>Polygon (MATIC): </span> The Polygon Chain is an efficient and cost-effective alternative to the Ethereum erc-20 network and is now being used by more and more players.</li>

                    <li><span class='font-medium'>Solana (SOL): </span> Solana is a new web 3 infrastructure which is ideal for application developers, there are also many NFT projects being launched on the Solana blockchain.</li>

                    <li><span class='font-medium'>Binance Coin (BNB): </span>Binance Coin is the native coin of the crypto exchange Binance. Recently, players have also been able to place bets with this currency on shuffle.</li>

                    <li><span class='font-medium'>Shiba Inu (SHIB): </span> The memecoin par excellence, with great hype this currency conquered the #1 meme-coin and is today a popular method of payment at the Shuffle Casino.</li>

                    <li><span class='font-medium'>Bonk (BONK): </span>Bonk is a new meme coin on the Solana blockchain which offers various integrations into applications and protocols in the web3 ecosystem including staking rewards, Raffles and now also on Shuffle as a betting currency.</li>

                    <li><span class='font-medium'>Dogwifhat (WIF): </span> Literally just a Dog wif a Hat.. that you can use to play online slots and games!</li>
                </ul>
            </div>              
        `
    },
    {
        question: 'Fast Deposits & Withdrawals for Gaming on Shuffle',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                Deposits on Shuffle are very quick and are done easily, follow the steps below to get started with your instant Crypto deposits:
            </p>
            <ul class='rubik leading-[150%] opacity-70 text-secondary list-disc px-2.5 min-[400px]:px-5'>
                    <li><span class='font-medium'>Step 1 -  </span>Get your deposit address, located in Wallet > Deposit.</li>

                    <li><span class='font-medium'>Step 2 - </span> Choose one of the available currencies. Shuffle offers many supported currencies including <span class='font-medium'>Bitcoin</span> (BTC), <span class='font-medium'>Ethereum</span> (ETH), <span class='font-medium'>Dogecoin</span> (Doge) and more.</li>

                    <li><span class='font-medium'>Step 3 - </span>  Use your deposit address as the 'Send to' location for your desired crypto wallet or exchange.</li>
                </ul>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                    All deposits and withdrawals at Shuffle are credited and processed as quickly as possible. Once the funds are on shuffle you can make transactions without any waiting time and bet, play and send money instantly.
                </p>
                <p class='rubik leading-[150%] text-secondary opacity-70'>
                    If you do not have any cryptocurrencies, you can alternatively use third-party providers such as <span class='font-medium'>Moonpay</span> or <span class='font-medium'>Banxa</span> to conveniently buy cryptocurrencies by credit card, Visa, Mastercard and other well-known payment methods to deposit funds on Shuffle.
                </p>
        `
    },
    {
        question: 'The $SHFL Token',
        answer: `
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                he latest innovation of the Shuffle Casino & Sportsbook platform is the utility token <span class='font-medium'>$SHFL</span> which is based on the erc-20 blockchain and is one of the biggest casino events with 3 widespread <span class='font-medium'>airdrops, VIP perks and regular buybacks & burns.</span>
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70 font-medium'>
                How to buy $shfl token?
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
               The Shuffle token can currently be purchased via the <span class='font-medium'>Web3 platform</span> Uniswap. A secure and decentralised exchange is used to ensure maximum transparency.
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70 font-medium'>
                How to claim the $shfl Airdrop?
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
                The first of three airdrops has already taken place, rewarding active players in the period <span class='font-medium'>Feb 2023 - Feb 2024.</span> The amount depends on various factors. <span class='font-medium'>20%</span> of the amount can be claimed immediately, while the other <span class='font-medium'>80%</span> is subject to a 3-month vesting phase. But there are still 2 more airdrops to come that you can look forward to!
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70 font-medium'>
                $shfl Buyback & Burn feature
            </p>
            <p class='rubik leading-[150%] text-secondary opacity-70'>
               Buybacks and token burns are carried out by Shuffle at regular intervals. The sum of the buybacks is <span class='font-medium'>15% of the revenue</span> with $shfl tokens, which means active players lead to larger burns.
            </p>
        `
    }

]

