import React from "react";

import websiteLogo from "./../../assets/images/pngs/websiteLogo.png";
import { PlayBtnSvg, SearchBtnSvg } from "../helper/Icons";

export default function Header() {
  return (
    <>
      <header className="bg-almostBlack border-b border-grey overflow-hidden">
        <nav className="max-w-[1380px] mx-auto px-4 md:px-6 lg:px-8 xl:px-12 py-2 md:py-3 lg:py-4">
          <div className="flex justify-between items-center">
            <div
              data-aos="zoom-in-right"
              className="max-w-40 md:max-w-52 lg:max-w-[278px]"
            >
              <a href="/#">
                <img src={websiteLogo} alt="Website Logo" />
              </a>
            </div>
            <div
              data-aos="zoom-in-left"
              className="flex gap-3 md:gap-4 lg:gap-6"
            >
              <button className="rubik flex items-center text-secondary opacity-80 text-xs sm:text-sm md:text-base transition-all ease-in-out duration-300 hover:opacity-100 gap-1 md:gap-2">
                <SearchBtnSvg />
                <span className="hidden min-[425px]:inline-block">Search</span>
              </button>
              <a
                href="http://shuffle.com/?r=ExtraBonus"
                className="inter bg-primary flex items-center gap-1.5 md:gap-2.5 text-secondary text-xs sm:text-sm md:text-base font-bold rounded-md transition-all ease-in-out duration-300 hover:bg-primary/80 hover:scale-105 hover:shadow-[0_0_5px] hover:shadow-primary/80 px-2 sm:px-3 md:px-4 lg:px-5 py-1 md:py-1.5 lg:py-2.5"
              >
                Play
                <PlayBtnSvg />
              </a>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
