import React from "react";
import Hero from "./homeComponents/Hero";
import Bonuses from "./homeComponents/Bonuses";
import Promotions from "./homeComponents/Promotions";
import AirDrop from "./homeComponents/AirDrop";
import Faq from "./homeComponents/Faq";
import HowToRegister from "./homeComponents/HowToRegister";

export default function Home() {
  return (
    <>
      <div className="overflow-hidden ">
        <Hero />
        <Bonuses />
        <HowToRegister />
        <Promotions />
        <AirDrop />
        <Faq />
      </div>
    </>
  );
}
