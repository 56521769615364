import React from "react";

import promotionsBg from "./../../../assets/images/pngs/promotionsBg.png";
import promotionsBgLeft from "./../../../assets/images/pngs/promotionsBgLeft.png";
import promotionsImgBackGround from "./../../../assets/images/pngs/promotionsImgBackGround.png";
import promotionsImgBackGroundLeft from "./../../../assets/images/pngs/promotionsImgBackGroundLeft.png";
import promotionsImg from "./../../../assets/images/pngs/promotionsImg.png";
import promotionOverlay from "./../../../assets/images/pngs/promotionOverlay.png";

export default function Promotions() {
  return (
    <>
      <div className="max-w-[1380px] mx-auto px-4 md:px-6 lg:px-8 xl:px-12 relative z-30">
        <div data-aos="fade-up" className="flex pt-5 pb-10">
          <div
            style={{ backgroundImage: `url(${promotionsBg})` }}
            className="[background-size:100%_100%] rounded-3xl bg-no-repeat flex flex-col min-[875px]:flex-row items-center gap-y-4"
          >
            <div
              data-aos="fade-right"
              style={{ backgroundImage: `url(${promotionsImgBackGround})` }}
              className="bg-primary min-[875px]:bg-opacity-0 [background-size:100%_100%] bg-right h-full bg-no-repeat rounded-3xl min-[875px]:rounded-r-none w-full min-[875px]:w-1/2 flex justify-center relative overflow-hidden"
            >
              <img
                src={promotionsImg}
                alt="promotions main pic"
                className="z-20 md:m-8 max-w-72 md:max-w-80 xl:max-w-96"
              />
              <div className="absolute top-0 min-[875px]:right-16 w-full z-10">
                <img src={promotionOverlay} alt="Overlay img" />
              </div>
            </div>
            <div
              data-aos="fade-up"
              className="w-full min-[875px]:w-1/2 text-center min-[875px]:text-start p-2 sm:p-4 md:p-6 lg:p-9 xl:p-12"
            >
              <h2
                data-aos="fade-up"
                className="rubik text-secondary leading-[140%] text-[22px] lg:text-3xl xl:text-[38px] font-extrabold [text-shadow:1px_4px_0_#000;]"
              >
                PROMOTIONS
              </h2>
              <p
                data-aos="fade-up"
                className="rubik text-secondary leading-[150%] text-sm md:text-base lg:text-lg !opacity-70 mt-2 md:mt-3 lg:mt-4 mb-4 md:mb-5 lg:mb-6 xl:mb-8"
              >
                Discover the latest Casino & Sports promotions for Shuffle and
                take advantage today by joining the Weekly $100K Race, the $5k
                Treasure Hunt, a Massive Poker Event or Sports promos like 3x XP
                on all Bets and many more!
              </p>
              <a href="https://shuffle.com/promotions?r=ExtraBonus&md-tab=register&modal=auth"
                data-aos="fade-up"
                className="inter inline-block leading-[150%] text-lg text-secondary bg-primary rounded-md font-bold transition-all ease-in-out duration-300 hover:bg-primary/80 hover:scale-95 hover:shadow-[0_0_5px] hover:shadow-primary/80 py-4 px-8 mb-6 min-[875px]:mb-0"
              >
                See all Promotions
              </a>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="flex pb-10 md:pb-14 lg:pb-20 xl:pb-24"
        >
          <div
            style={{ backgroundImage: `url(${promotionsBgLeft})` }}
            className="[background-size:100%_100%] rounded-3xl bg-no-repeat flex flex-col min-[875px]:flex-row-reverse items-center gap-y-4"
          >
            <div
              data-aos="fade-right"
              style={{ backgroundImage: `url(${promotionsImgBackGroundLeft})` }}
              className="bg-blueViolet min-[875px]:bg-opacity-0 [background-size:100%_100%] bg-right h-full bg-no-repeat rounded-3xl min-[875px]:rounded-l-none w-full min-[875px]:w-1/2 flex justify-center relative overflow-hidden"
            >
              <img
                src={promotionsImg}
                alt="promotions main pic"
                className="z-20 md:m-8 max-w-72 md:max-w-80 xl:max-w-96"
              />
              <div className="absolute top-0 min-[875px]:left-20 w-full z-10">
                <img src={promotionOverlay} alt="Overlay img" />
              </div>
            </div>
            <div
              data-aos="fade-up"
              className="w-full min-[875px]:w-1/2 text-center min-[875px]:text-start p-2 sm:p-4 md:p-6 lg:p-9 xl:p-12"
            >
              <h2
                data-aos="fade-up"
                className="rubik text-secondary leading-[140%] text-[22px] lg:text-3xl xl:text-[38px] font-extrabold [text-shadow:1px_4px_0_#000;]"
              >
                CHALLENGES
              </h2>
              <p
                data-aos="fade-up"
                className="rubik text-secondary leading-[150%] text-sm md:text-base lg:text-lg !opacity-70 mt-2 md:mt-3 lg:mt-4 mb-4 md:mb-5 lg:mb-6 xl:mb-8"
              >
                Discover the latest Casino & Sports promotions for Shuffle and
                take advantage today by joining the Weekly $100K Race, the $5k
                Treasure Hunt, a Massive Poker Event or Sports promos like 3x XP
                on all Bets and many more!
              </p>
              <a href="https://shuffle.com/challenges?r=ExtraBonus&md-tab=register&modal=auth"
                data-aos="fade-up"
                className="inter inline-block leading-[150%] text-lg text-secondary bg-primary rounded-md font-bold transition-all ease-in-out duration-300 hover:bg-primary/80 hover:scale-95 hover:shadow-[0_0_5px] hover:shadow-primary/80 py-4 px-8 mb-6 min-[875px]:mb-0"
              >
                See all Promotions
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
