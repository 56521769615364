import React from "react";

import heroBg from "./../../../assets/images/pngs/heroBg.png";
import heroImg from "./../../../assets/images/pngs/heroImg.png";
import heroSideFrame from "./../../../assets/images/pngs/heroSideFrame.png";

export default function Hero() {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${heroBg})` }}
        className="bg-cover bg-no-repeat relative z-10"
      >
        <div className="max-w-[1380px] mx-auto px-4 md:px-6 lg:px-8 xl:px-12">
          <div className="flex flex-col sm:flex-row  min-h-[70vh] ">
            <div
              data-aos="zoom-in-right"
              className="place-content-center text-center sm:text-start text-nowrap z-30 pt-10 sm:pt-0  my-auto"
            >
              <h1 className="rubik text-secondary text-[28px] min-[375px]:text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase font-bold [text-shadow:0_3px_0_#2B1052;]">
                Bet big at <span className="text-primary">Shuffle</span>
              </h1>
              <p className="rubik text-secondary !leading-[150%] sm:text-lg md:text-xl lg:text-2xl xl:text-[28px] font-bold opacity-90 text-wrap mt-1.5 md:mt-2 lg:mt-2.5 mb-4 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-12">
                VIP crypto casino, top games, and massive rewards. Join the
                action now!
              </p>
              <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth"
                data-aos="fade-up"
                className="rubik text-secondary bg-primary rounded-md uppercase leading-[150%] text-sm sm:text-base md:text-xl lg::text-2xl font-bold transition-all ease-in-out duration-300 hover:bg-primary/80 hover:scale-95 hover:shadow-[0_0_5px] hover:shadow-primary/80 px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 py-2 md:py-3 lg:py-4 xl:py-5"
              >
                REGISTER
              </a>
            </div>
            <div data-aos="zoom-in-left" className="max-w-[658px] mt-auto">
              <img src={heroImg} alt="Hero pic" />
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="absolute left-0 top-0 h-full z-20">
          <img
            src={heroSideFrame}
            alt="Hero Side Frame img"
            className="h-full"
          />
        </div>
        <div className="absolute top-0 left-0 [box-shadow:inset_0_-60px_30px_0_#0E0814;] w-full h-full z-20"></div>
      </div>
    </>
  );
}
