import React from "react";
import footerLogo from "./../../assets/images/pngs/footerLogo.png";

import { DiscordSvg, TelegramSvg, TwitterSvg } from "../helper/Icons";
import pokerFlush from "./../../assets/images/pngs/pokerFlush.png";
export default function Footer() {
  return (
    <>
      <div data-aos="fade-up" className="bg-esbony">
        <div className="max-w-[1380px] mx-auto relative px-4 md:px-6 lg:px-8 xl:px-12">
          <div className="flex flex-col lg:flex-row justify-between gap-4 md:gap-6 lg:gap-20 xl:gap-36 pt-8 md:pt-10 lg:pt-12 xl:pt-14 relative z-30">
            <div data-aos="fade-up" className="lg:max-w-[335px] xl:max-w-[377px]">
              <div className="max-w-40 md:max-w-52 lg:max-w-60 xl:max-w-80">
                <a href="/#">
                  <img src={footerLogo} alt="Website Logo" />
                </a>
              </div>
              <p className="rubik lg:text-lg text-secondary leading-[150%] opacity-70 mt-2 sm:mt-4">
                The #1 when it comes to rewarding players at Shuffle. Earn even
                bigger bonuses when you spin and bet with our exclusive Shuffle
                offers!
              </p>
            </div>
            <div className="flex flex-wrap min-[500px]:flex-nowrap gap-6 min-[500px]:gap-0 justify-between w-full">
              <div data-aos="fade-up">
                <h4 className="rubik text-secondary text-lg md:text-xl lg:text-2xl font-medium leading-[150%] uppercase mb-2 md:mb-4 lg:mb-6">
                  Exclusive
                </h4>
                <ul className="space-y-1 md:space-y-2">
                  <li>
                    <a
                      href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth"
                      className="rubik text-dustyGrey text-sm md:text-lg leading-[150%] transition-all ease-in-out delay-300 hover:text-secondary"
                    >
                      Deposit Bonus
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://shuffle.com/vip-program"
                      className="rubik text-dustyGrey text-sm md:text-lg leading-[150%] transition-all ease-in-out delay-300 hover:text-secondary"
                    >
                      VIP Level Ups
                    </a>
                  </li>
                </ul>
              </div>
              <div data-aos="fade-up">
                <h4 className="rubik text-secondary text-lg md:text-xl lg:text-2xl font-medium leading-[150%] uppercase mb-2 md:mb-4 lg:mb-6">
                  Design
                </h4>
                <ul className="space-y-1 md:space-y-2">
                  <li>
                    <a
                      href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth"
                      className="rubik text-dustyGrey text-sm md:text-lg leading-[150%] transition-all ease-in-out delay-300 hover:text-secondary"
                    >
                      Bonuses
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://shuffle.com/promotions?r=ExtraBonus&md-tab=register&modal=auth"
                      className="rubik text-dustyGrey text-sm md:text-lg leading-[150%] transition-all ease-in-out delay-300 hover:text-secondary"
                    >
                      Promotions
                    </a>
                  </li>
                </ul>
              </div>
              <div data-aos="fade-up">
                <h4 className="rubik text-secondary text-lg md:text-xl lg:text-2xl font-medium leading-[150%] uppercase mb-2 md:mb-4 lg:mb-6">
                  SOCIAL MEDIA
                </h4>
                <ul className="space-x-1 md:space-x-2 flex justify-between">
                  <li>
                    <a
                      href="https://x.com/shuffleguide" rel="noreferrer"
                      className="group transition-all ease-in-out delay-300"
                    >
                      <TwitterSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/ShuffleBoostOfficial" rel="noreferrer"
                      className="group transition-all ease-in-out delay-300"
                    >
                      <TelegramSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.com/invite/DogeGamba" rel="noreferrer"
                      className="group transition-all ease-in-out delay-300"
                    >
                      <DiscordSvg />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="flex justify-center min-[500px]:justify-end py-4 md:pb-8 lg:pb-10 xl:pb-14"
          >
            <select className="rubik text-secondary cursor-pointer border border-grey outline-none rounded-md bg-grey/85 opacity-70 text-lg leading-[150%] transition-all ease-in-out delay-300 hover:text-secondary px-5 py-2.5">
              <option value="English">English </option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
            </select>
          </div>

          <div className="absolute left-0 bottom-20 max-w-14 md:max-w-16 lg:max-w-20 xl:max-w-28 z-10">
            <img src={pokerFlush} alt="pokerFlush pic" className="rotate-90" />
          </div>
        </div>
        <div className="border-t border-grey">
          <div className="flex justify-center items-center max-w-[1380px] mx-auto relative px-4 md:px-6 lg:px-8 xl:px-12 py-4 md:py-6 lg:py-8 ">
            <p className="font-bold text-secondary">
              Disclaimer :
              <span className="rubik text-secondary opacity-70 leading-[150%] ml-2 font-normal">
                This site is not operated by <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth" className="transition-all ease-in-out duration-300 hover:underline">Shuffle.com</a>, this is not an official site of the <a href="https://shuffle.com/?r=ExtraBonus&md-tab=register&modal=auth" className="transition-all ease-in-out duration-300 hover:underline">Shuffle.com</a>. Gamble responsibly. Must be 18+ to play
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
