export const SearchBtnSvg = () => {
    return (
        <>
            <svg className="max-w-4 lg:max-w-none" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path d="M16.7347 14.7857C17.6636 13.5635 18.2258 12.2069 18.4213 10.7157C18.6169 9.22464 18.4458 7.77019 17.908 6.35242C17.3702 4.93464 16.5147 3.72464 15.3413 2.72242C14.168 1.72019 12.848 1.06019 11.3813 0.742416C9.91468 0.424639 8.46024 0.48575 7.01801 0.925749C5.57579 1.36575 4.30468 2.12353 3.20468 3.19908C2.10468 4.27464 1.3469 5.53353 0.931347 6.97575C0.515792 8.41797 0.466903 9.87242 0.784681 11.3391C1.10246 12.8057 1.76246 14.1257 2.76468 15.2991C3.7669 16.4724 4.9769 17.328 6.39468 17.8657C7.81246 18.4035 9.2669 18.5746 10.758 18.3791C12.2491 18.1835 13.6058 17.6213 14.828 16.6924L20.2547 22.1924C20.4991 22.4369 20.8169 22.5591 21.208 22.5591C21.5991 22.5591 21.9291 22.4246 22.198 22.1557C22.4669 21.8869 22.6013 21.5569 22.6013 21.1657C22.6013 20.7746 22.4791 20.4569 22.2347 20.2124L16.7347 14.7857ZM17.1013 9.50575C17.1013 10.8746 16.7591 12.1335 16.0747 13.2824C15.3902 14.4313 14.4736 15.348 13.3247 16.0324C12.1758 16.7169 10.9169 17.0591 9.54801 17.0591C8.17912 17.0591 6.90801 16.7169 5.73468 16.0324C4.56135 15.348 3.64468 14.4313 2.98468 13.2824C2.32468 12.1335 1.99468 10.8746 1.99468 9.50575C1.99468 8.13686 2.32468 6.86575 2.98468 5.69242C3.64468 4.51908 4.56135 3.60242 5.73468 2.94242C6.90801 2.28242 8.17912 1.95242 9.54801 1.95242C10.9169 1.95242 12.1758 2.28242 13.3247 2.94242C14.4736 3.60242 15.3902 4.51908 16.0747 5.69242C16.7591 6.86575 17.1013 8.13686 17.1013 9.50575Z" fill="white" fillOpacity="0.8" />
            </svg>
        </>)
}
export const PlayBtnSvg = () => {
    return (
        <>
            <svg className="max-w-4 lg:max-w-none" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path d="M9.07812 14.6264V7.4584C9.07827 7.36871 9.10254 7.28072 9.14839 7.20364C9.19423 7.12656 9.25997 7.06323 9.3387 7.02028C9.41743 6.97734 9.50627 6.95636 9.59589 6.95954C9.68552 6.96273 9.77264 6.98997 9.84813 7.0384L15.4241 10.6214C15.4947 10.6666 15.5528 10.7289 15.5931 10.8025C15.6333 10.876 15.6544 10.9585 15.6544 11.0424C15.6544 11.1262 15.6333 11.2088 15.5931 11.2823C15.5528 11.3559 15.4947 11.4182 15.4241 11.4634L9.84813 15.0474C9.77264 15.0958 9.68552 15.1231 9.59589 15.1262C9.50627 15.1294 9.41743 15.1085 9.3387 15.0655C9.25997 15.0226 9.19423 14.9592 9.14839 14.8822C9.10254 14.8051 9.07827 14.7171 9.07812 14.6274V14.6264Z" fill="white" />
                <path d="M0.578125 11.0425C0.578125 4.96748 5.50313 0.0424805 11.5781 0.0424805C17.6531 0.0424805 22.5781 4.96748 22.5781 11.0425C22.5781 17.1175 17.6531 22.0425 11.5781 22.0425C5.50313 22.0425 0.578125 17.1175 0.578125 11.0425ZM11.5781 1.54248C9.05857 1.54248 6.64221 2.54337 4.86061 4.32497C3.07901 6.10656 2.07812 8.52292 2.07812 11.0425C2.07812 13.562 3.07901 15.9784 4.86061 17.76C6.64221 19.5416 9.05857 20.5425 11.5781 20.5425C14.0977 20.5425 16.514 19.5416 18.2956 17.76C20.0772 15.9784 21.0781 13.562 21.0781 11.0425C21.0781 8.52292 20.0772 6.10656 18.2956 4.32497C16.514 2.54337 14.0977 1.54248 11.5781 1.54248Z" fill="white" />
            </svg>
        </>)
}
export const PlusSvg = () => {
    return (
        <>
            <svg className="max-w-4 lg:max-w-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3075 9.2105C18.3075 9.50006 18.1924 9.77776 17.9877 9.9825C17.7829 10.1873 17.5052 10.3023 17.2157 10.3023H10.3011V17.2169C10.3011 17.5065 10.186 17.7842 9.98128 17.9889C9.77654 18.1937 9.49884 18.3087 9.20928 18.3087C8.91972 18.3087 8.64202 18.1937 8.43727 17.9889C8.23252 17.7842 8.11749 17.5065 8.11749 17.2169V10.3023H1.20287C0.913308 10.3023 0.635609 10.1873 0.43086 9.9825C0.226111 9.77776 0.111084 9.50006 0.111084 9.2105C0.111084 8.92094 0.226111 8.64324 0.43086 8.43849C0.635609 8.23374 0.913308 8.11871 1.20287 8.11871H8.11749V1.20409C8.11749 0.914529 8.23252 0.63683 8.43727 0.432081C8.64202 0.227332 8.91972 0.112305 9.20928 0.112305C9.49884 0.112305 9.77654 0.227332 9.98128 0.432081C10.186 0.63683 10.3011 0.914529 10.3011 1.20409V8.11871H17.2157C17.5052 8.11871 17.7829 8.23374 17.9877 8.43849C18.1924 8.64324 18.3075 8.92094 18.3075 9.2105Z" fill="white" />
            </svg>
        </>)
}
export const LessSvg = () => {
    return (
        <>
            <svg className="max-w-4 lg:max-w-5" width="21" height="3" viewBox="0 0 21 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3076 1.0415L1.57494 1.0415L19.3076 1.0415Z" fill="white" />
                <path d="M19.3076 1.0415L1.57494 1.0415" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>)
}
export const TwitterSvg = () => {
    return (
        <>
            <svg  width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle className="group-hover:fill-primary" cx="21.3503" cy="21.5432" r="21.0395" transform="rotate(0.0901378 21.3503 21.5432)" fill="#1D2128" />
                <mask id="mask0_21_1004" maskUnits="userSpaceOnUse" x="11" y="11" width="20" height="21">
                    <path d="M11.8276 11.9902L30.9035 12.0202L30.8735 31.0961L11.7976 31.0661L11.8276 11.9902Z" fill="white" />
                </mask>
                <g mask="url(#mask0_21_1004)">
                    <path d="M25.8045 14.157L28.2582 14.1609L22.8883 20.4153L29.1812 28.949L24.244 28.9412L20.3825 23.7666L15.9515 28.9281L13.4955 28.9243L19.2386 22.2323L13.2045 14.1384L18.2673 14.1463L21.7525 18.8752L25.8045 14.157ZM24.9207 27.4412L26.2807 27.4434L17.5222 15.567L16.0639 15.5647L24.9207 27.4412Z" fill="white" />
                </g>
            </svg>
        </>)
}
export const TelegramSvg = () => {
    return (
        <>
            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle className="group-hover:fill-primary" cx="21.5288" cy="21.6253" r="21.0395" transform="rotate(0.0901378 21.5288 21.6253)" fill="#1D2128" />
                <path fillRule="evenodd" clipRule="evenodd" d="M27.1606 14.2856C27.3575 14.1957 27.5728 14.1649 27.7843 14.1965C27.9958 14.228 28.1956 14.3208 28.3629 14.4652C28.5303 14.6095 28.6591 14.8001 28.736 15.0172C28.8128 15.2343 28.8349 15.4698 28.7999 15.6994L26.9756 27.6357C26.7987 28.787 25.6386 29.4456 24.6706 28.8705C23.861 28.3893 22.6585 27.648 21.5771 26.8747C21.0364 26.4877 19.3801 25.2484 19.5853 24.3687C19.7616 23.6165 22.5523 20.7934 24.1471 19.1149C24.773 18.4554 24.4886 18.074 23.7497 18.6803C21.9148 20.1858 18.9689 22.4749 17.9951 23.1191C17.136 23.687 16.6885 23.7835 16.1537 23.6855C15.178 23.5069 14.2733 23.2312 13.5351 22.8959C12.5375 22.4431 12.5884 20.947 13.538 20.5128L27.1606 14.2856Z" fill="white" />
            </svg>
        </>)
}
export const DiscordSvg = () => {
    return (
        <>
            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle className="group-hover:fill-primary" cx="21.7073" cy="21.7073" r="21.0395" transform="rotate(0.0901378 21.7073 21.7073)" fill="#1D2128" />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.4683 13.2859C29.5015 13.2875 30.3414 14.0646 30.3399 15.0258L30.3161 30.1525L28.3529 28.5473L27.2481 27.6012L26.0794 26.596L26.5615 28.1566L16.1935 28.1403C15.1604 28.1387 14.3205 27.3617 14.322 26.4004L14.3399 15.0006C14.3414 14.0394 15.1838 13.265 16.2169 13.2666L28.4683 13.2859ZM24.9219 24.2923C26.9425 24.2364 27.7216 23.0151 27.7216 23.0151C27.7259 20.3 26.4128 18.0972 26.4128 18.0972C25.0976 17.1845 23.845 17.2079 23.845 17.2079L23.7168 17.3426C25.2704 17.7835 25.9917 18.417 25.9917 18.417C25.0416 17.9349 24.1094 17.6973 23.241 17.6032C22.5828 17.5347 21.9519 17.5506 21.3941 17.6172L21.2386 17.6338C20.9186 17.6586 20.1413 17.767 19.1624 18.1617C18.8239 18.3045 18.6226 18.4054 18.6226 18.4054C18.6226 18.4054 19.3825 17.7405 21.0289 17.3046L20.9376 17.2033C20.9376 17.2033 19.6851 17.176 18.3671 18.0846C18.3671 18.0846 17.047 20.2832 17.0428 22.9983C17.0428 22.9983 17.8088 24.2221 19.8293 24.2843C19.8293 24.2843 20.1682 23.9054 20.443 23.5854C19.2824 23.2632 18.8446 22.5879 18.8446 22.5879L19.1003 22.7317L19.1369 22.757L19.1726 22.7761L19.1833 22.7803L19.2191 22.7993C19.4475 22.9177 19.6759 23.0108 19.8861 23.087C20.2607 23.2225 20.7085 23.3582 21.2295 23.4517C21.915 23.5709 22.7195 23.6143 23.5975 23.4639C24.0273 23.3971 24.4663 23.2798 24.9238 23.1034C25.2439 22.9943 25.6008 22.8347 25.976 22.6076C25.976 22.6076 25.5177 23.2983 24.3195 23.6084C24.5933 23.9292 24.9219 24.2923 24.9219 24.2923ZM20.6767 20.3479C20.1555 20.3471 19.7434 20.7681 19.7426 21.2824C19.7418 21.7967 20.1617 22.219 20.6737 22.2198C21.1949 22.2206 21.6069 21.7997 21.6078 21.2853C21.6177 20.771 21.1978 20.3488 20.6767 20.3479ZM24.0138 20.3532C23.4927 20.3524 23.0806 20.7733 23.0798 21.2877C23.0789 21.802 23.4989 22.2243 24.0109 22.2251C24.532 22.2259 24.9441 21.8049 24.9449 21.2906C24.9457 20.7763 24.5349 20.354 24.0138 20.3532Z" fill="white" />
            </svg>
        </>)
}

